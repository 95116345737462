import React, { useEffect, useState } from 'react';
import { useSpring, a, config } from 'react-spring';

export default function DesarrolloHero({
  logo,
  desarrolloTitle,
  heroImg,
  bgColorClass,
}) {
  /**
   * Title fade in animations
   */
  const fadeInSpring = useSpring({
    from: { opacity: 0, y: 80 },
    opacity: 1,
    y: 0,
    config: config.slow,
  });

  const fadeInDelay = useSpring({
    from: { opacity: 0 },
    opacity: 1,
    delay: 600,
    config: config.slow,
  });

  /**
   * Image parallax
   * Tracks the scrollY position every 100ms.
   * Use scrollY value to translateY hero image.
   * * scrollY value is relative to the top of the page
   */
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const onScroll = (e) => {
      setScrollY(window.scrollY);
    };

    const timer = setTimeout(() => {
      window.addEventListener('scroll', onScroll);
    }, 100);

    return () => {
      window.removeEventListener('scroll', onScroll);
      clearTimeout(timer);
    };
  }, [scrollY]);

  const { y } = useSpring({ y: scrollY / -10 });

  return (
    <div className={`h-screen w-full pt-28 ${bgColorClass}`}>
      <a.div style={fadeInSpring} className="flex flex-col items-center">
        {logo}
        <h1 className="text-white lg:text-9xl">La Hacienda</h1>
      </a.div>
      <a.div
        style={{ y }}
        className="w-11/12 mx-auto relative justify-center mt-16">
        <a.h1
          style={fadeInDelay}
          className="absolute inset-0 transform -translate-y-12 z-[1] text-center text-white text-7xl">
          {desarrolloTitle}
        </a.h1>
        {heroImg}
      </a.div>
    </div>
  );
}
