import React from 'react';

export default function FullscreenImageParallax({ bgImage, text }) {
  return (
    <div className="relative w-full min-h-screen flex items-center overflow-hidden">
      <div className="w-full h-full fixed inset-0 z-[-1]">{bgImage}</div>
      <div className="absolute w-full h-full bg-black opacity-50"></div>
      <h1 className="text-center text-4xl xl:text-7xl max-w-[1400px] px-6 mx-auto text-white z-[1] tracking-wide">
        {text}
      </h1>
    </div>
  );
}
