import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function Ubicacion({ titleColorClass }) {
  return (
    <div className="container mx-auto pt-20">
      <h1 className={'md:text-9xl text-center ' + titleColorClass}>
        Ubicación
      </h1>
      <div className="md:flex items-center py-10">
        <StaticImage
          alt="Mapa para llegar a la Hacienda desde el centro de Formosa"
          src="../../images/lh-ubicacion.png"
          className="lg:w-1/3"
        />
        <div className="text-3xl max-w-[700px] mt-10 md:mt-0 md:ml-20 px-6">
          <p className="font-bold">
            A minutos de la ciudad a través de la futura autovía.
          </p>
          <p className="font-bold m-0">
            Un refugio natural donde podrás tener tu huerta, tus mascotas y con
            la tranquilidad que da el campo.
          </p>
        </div>
      </div>
    </div>
  );
}
