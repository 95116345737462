import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useSpring, a, config } from 'react-spring';
import useInView from 'react-cool-inview';

import Layout from '../components/layout/layout';
import Seo from '../components/seo/seo';
import DesarrolloHero from '../components/sections/desarrolloHero';
import Brochure from '../components/sections/brochure';
import Ubicacion from '../components/sections/ubicacion';
import FullscreenImageParallax from '../components/sections/fullscreenImageParallax';

const LaHaciendaChacrasPage = () => {
  /**
   * Fade in animation on enter view
   */
  const { observe, inView } = useInView({
    // Stop observe when the target enters the viewport, so the "inView" only triggered once
    unobserveOnEnter: true,
    // Shrink the root margin, so the animation will be triggered once the target reach a fixed amount of visible
    rootMargin: '-300px 0px',
  });
  const fadeInSpring = useSpring({
    opacity: inView ? 1 : 0,
    y: inView ? 0 : 20,
    config: config.slow,
  });

  return (
    <Layout>
      <Seo title="La Hacienda: Club de Chacras" />

      {/* Hero */}
      <DesarrolloHero
        logo={
          <StaticImage
            alt="Logo La Hacienda Country Club"
            src="../images/LH-chacras-logo_mini-dark.jpg"
            className="w-24"
          />
        }
        desarrolloTitle="Club de Chacras"
        heroImg={
          <StaticImage
            alt="Entrada al CLub Country La Hacienda"
            src="../images/lh-chacras-hero.jpg"
            className="rounded-md h-96 lg:w-full lg:h-auto shadow-xl"
          />
        }
        bgColorClass="bg-chacras-500"
      />

      {/* Brochure */}
      <Brochure
        bgLightClass="bg-chacras-100"
        bgDarkClass="bg-chacras-500"
        textColorClass="text-chacras-500"
        borderColorClass="border-chacras-500"
      />

      <div className="bg-gray-100">
        {/* Collage */}
        <div>
          <div className="grid gap-2 p-2 grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
            <StaticImage
              alt="muelle con reposeras"
              src="../images/lh-chacras-gallery-1.jpeg"
              className="order-1 md:row-span-1 rounded"
            />
            <StaticImage
              alt="mujer haciendo ejercicio al aire libre"
              src="../images/lh-chacras-gallery-2.jpg"
              className="order-2 md:row-span-3 md:order-3 xl:order-5 rounded"
            />
            <StaticImage
              alt="hombre jugando al tenis"
              src="../images/lh-chacras-gallery-3.jpeg"
              className="order-3 md:row-span-2 md:order-2 xl:order-6"
              rounded
            />
            <StaticImage
              alt="niñas divirtiendose en la pileta"
              src="../images/lh-chacras-gallery-4.jpg"
              className="order-4 md:row-span-2 xl:order-2 rounded"
            />
            <div className="flex items-center justify-center p-6 order-5 md:row-span-2 xl:order-3 rounded bg-chacras-100">
              <p className="m-0 text-center text-2xl text-chacras-500 font-bold">
                Ideal para invertir en calidad de vida! Lo mejor del campo, a
                minutos de la ciudad!
              </p>
            </div>
            <StaticImage
              alt="casa lujosa en barrio privado"
              src="../images/lh-chacras-gallery-5.jpeg"
              className="order-6 md:order-7 md:row-span-2 rounded"
            />
            <StaticImage
              alt="niña practicando golf"
              src="../images/lh-chacras-gallery-6.jpg"
              className="order-7 md:row-span-4 md:order-6 xl:order-4 rounded"
            />
          </div>
        </div>

        {/* Ubicacion */}
        <Ubicacion titleColorClass="text-chacras-500" />
      </div>

      {/* Parallax */}
      <FullscreenImageParallax
        bgImage={
          <StaticImage
            alt="Barrio privado La Hacienda"
            src="../images/lh-chacras-porton.jpeg"
            className="w-full h-full "
          />
        }
        text="Un desarrollo inmobiliario donde proyectar tu casa, tu sueño, tu estilo de vida, junto a la naturaleza y la tranquilidad de un entorno que te permita vivir a otro ritmo!"
      />

      {/* Masterplan */}
      <div className="bg-gray-100 py-20">
        <h1 className="md:text-9xl text-chacras-500 text-center mb-10">
          Masterplan
        </h1>
        <div className="w-11/12 mx-auto" ref={observe}>
          <a.div className="w-full" style={fadeInSpring}>
            <StaticImage
              alt="Barrio privado La Hacienda"
              src="../images/lh-chacras-master-abc.png"
              className="w-full h-full "
            />
          </a.div>
          <a.div className="w-full" style={fadeInSpring}>
            <StaticImage
              alt="Barrio privado La Hacienda"
              src="../images/lh-chacras-master-ab.png"
              className="w-full h-full "
            />
          </a.div>
          <a.div className="w-full" style={fadeInSpring}>
            <StaticImage
              alt="Barrio privado La Hacienda"
              src="../images/lh-chacras-master-c.png"
              className="w-full h-full "
            />
          </a.div>
        </div>
      </div>
    </Layout>
  );
};

export default LaHaciendaChacrasPage;
