import React from 'react';
import { FiArrowDownCircle } from 'react-icons/fi';

import brochure from '../../download/BROCHURE_LH_MAY2021.pdf';
import reglamento from '../../download/REGLAMENTO_LA_HACIENDA_JUN21.pdf';

export default function Brochure({
  bgLightClass,
  bgDarkClass,
  textColorClass,
  borderColorClass,
}) {
  return (
    <div className={'h-screen w-full flex items-center ' + bgLightClass}>
      <div className="w-11/12 mx-auto xl:flex xl:items-center justify-evenly transform lg:translate-y-20 xl:translate-y-40">
        <p
          className={
            'max-w-[750px] xl:m-0 text-2xl md:text-3xl font-bold tracking-wide leading-relaxed ' +
            textColorClass
          }>
          Un espacio donde se conjugan naturaleza, armonía y paz para pasar tus
          fines de semana, vacaciones o residir en forma permanente en familia y
          rodeados de amigos.
        </p>

        <div className="flex flex-col items-start">
          <a
            className="relative inline-block mb-6 xl:mt-0 transform transition hover:scale-105"
            href={reglamento}
            download>
            <div
              className={
                'absolute inset-0 h-full w-full transform translate-x-2 translate-y-2 rounded-md ' +
                bgDarkClass
              }></div>
            <button
              className={`relative flex items-center px-6 py-3 border-2 rounded-md ${borderColorClass} ${textColorClass} ${bgLightClass} z-[1] transform transition active:translate-x-2 active:translate-y-2`}>
              <p className="m-0 font-bold text-xl mr-6">Reglamento</p>
              <FiArrowDownCircle className="text-2xl" />
            </button>
          </a>
          <a
            className="relative inline-block xl:mt-0 transform transition hover:scale-105"
            href={brochure}
            download>
            <div
              className={
                'absolute inset-0 h-full w-full transform translate-x-2 translate-y-2 rounded-md ' +
                bgDarkClass
              }></div>
            <button
              className={`relative flex items-center px-6 py-3 border-2 rounded-md ${borderColorClass} ${textColorClass} ${bgLightClass} z-[1] transform transition active:translate-x-2 active:translate-y-2`}>
              <p className="m-0 font-bold text-xl mr-6">Brochure</p>
              <FiArrowDownCircle className="text-2xl" />
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}
